import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Plugin } from 'vue-fragment';
const cssVars = require('./styles/variables.scss');

import Vue2TouchEvents from 'vue2-touch-events';
import VueMq from 'vue-mq';
import VueCookies from 'vue-cookies';
import VueLazyload from 'vue-lazyload';

Vue.use(Vue2TouchEvents);

Vue.use(VueMq, {
  breakpoints: {
    mobileSM: parseInt(cssVars.mobileSM, 10),
    mobile: parseInt(cssVars.mobile, 10) + 1,
    mobileLG: parseInt(cssVars.mobileLG, 10),
    tabletSM: parseInt(cssVars.tabletSM, 10),
    tablet: parseInt(cssVars.tablet, 10),
    tabletLG: parseInt(cssVars.tabletLG, 10),
    desktopSM: parseInt(cssVars.desktopSM, 10),
    desktop: parseInt(cssVars.desktop, 10),
    desktopLG: parseInt(cssVars.desktopLG, 10),
    desktopXL: parseInt(cssVars.desktopXL, 10),
    desktopXXL: Infinity,
  },
});

Vue.use(VueCookies);
Vue.use(VueLazyload);
Vue.use(Plugin);
Vue.config.productionTip = false;

Vue.prototype.$window = window;

// enables detection of clicks outside a template element
// use: v-click-outside="methodToCall"
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // check that click was outside the element
      if (!(el == event.target || el.contains(event.target))) {
        // if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
