<template>
  <fragment>
    <header id="nav-desktop">
      <div class="container-rs show">
        <div class="li-rs">
          <a href="https://github.com/serviox19" target="_blank">
            <img src="/icons/github-w.svg" />
          </a>
          <a href="https://www.linkedin.com/in/servio-mora-b93972108/" target="_blank">
            <img src="/icons/linkedin-w.svg" />
          </a>
          <a href="https://twitter.com/servio_io" target="_blank">
            <img src="/icons/twitter-w.svg" />
          </a>
        </div>
      </div>
      <a class="container-logo" href="">
        LOGO
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 37">
          <use xlink:href=""></use>
        </svg>
      </a>
    </header>

    <div id="nav-btn"
      :class="{ 'active': navToggled }" @click="toggleNav">
      <div class="content">
        <div class="text">
          <span>M</span>
          <span>E</span>
          <span>N</span>
          <span>U</span>
        </div>
        <div class="action">
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
      </div>
    </div>

    <nav id="nav" :class="{ 'displayBlock': navToggled, 'show': navToggled }">
      <div class="navigation-backdrop" @click="closeNavDesktop"></div>
      <div class="content" :class="{ 'active': navToggled }">
        <div class="head">
          <a class="container-logo" href="">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 37">
              <use xlink:href=""></use>
            </svg>
          </a>
          <div class="container-vr" style="display:none">V.4</div>
        </div>
        <div class="container-rs">
          <div class="li-rs">
            <a href="https://github.com/serviox19" target="_blank">
              <img src="/icons/github.svg" />
            </a>
            <a href="https://www.linkedin.com/in/servio-m-b93972108/" target="_blank">
              <img src="/icons/linkedin.svg" />
            </a>
            <a href="https://twitter.com/servio_io" target="_blank">
              <img src="/icons/twitter.svg" />
            </a>
          </div>
        </div>
        <div class="container-prLink">
          <ul class="page-services">
            <li class="link-home">
              <a data-content="el-home" class="delay" href="/">HOME</a>
            </li>
            <li class="link-projects">
              <a data-content="el-projects" class="delay" href="/projects">PROJECTS</a>
            </li>
            <li class="link-blog">
              <a data-content="el-blog" class="delay" href="/blog">BLOG</a>
            </li>
          </ul>
        </div>
        <div class="container-contentLink">
          <div class="wrapper-el">
            <div class="el el-home">
              <div class="container-text">
                <h3>My Mission</h3>
                <p>Full Stack Web Developer with a Front End focus interested in all things technology and finance. I am committed to continuously learn new skills and find solutions to some of the most complex problems out there.</p>
              </div>
            </div>
            <div class="el el-projects">
              <div class="container-text">
                <h3>BLOOD, SWEAT, AND DETERMINATION MAKES GREAT WORK</h3>
                <p>I pride myself on the pride and attention to detail that I put in to all my projects.</p>
              </div>
            </div>
            <div class="el el-blog">
              <div class="container-text">
                <h3>ANYONE CAN INSPIRE, EMPOWER, AND EDUCATE THE WORLD</h3>
                <p>Get your cup of coffee and enjoy some creative and touching thoughts that float through my mind.</p>
              </div>
            </div>
            <div class="el el-contact">
              <div class="container-text">
                <h3>HIRE ME</h3>
                <p>Describe your current needs, and I will be sure to get back to you within 24 hours so we can see if our interests align.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </nav>

    <div id="nav-btn-mobile">
      <div class="content">
        <div class="container-bar">
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
        <div class="container-text">
          <span>MENU</span>
        </div>
      </div>
    </div>

    <div id="nav-mobile">

      <div class="head">

        <a class="container-logo" href="">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 37">
            <use xlink:href=""></use>
          </svg>
        </a>
        <div class="container-vr" style="display:none">S</div>
      </div>

      <div class="wrapper">

        <div class="container container-linkPr">
          <ul>
            <li>
              <a class="delay" href="/">HOME</a>
            </li>
            <li>
              <a class="delay" href="/blog">BLOG</a>
            </li>
            <li>
              <a class="delay" href="">PROJECTS</a>
              <span>3</span>
            </li>
            <li>
              <a class="delay" href="/contact">CONTACT</a>
            </li>
          </ul>
          <div class="li-rs">
            <a href="https://github.com/serviox19" target="_blank">
              <img src="/icons/github.svg" />
            </a>
            <a href="https://www.linkedin.com/in/servio-m-b93972108/" target="_blank">
              <img src="/icons/linkedin.svg" />
            </a>
            <a href="https://twitter.com/" target="_blank">
              <img src="/icons/twitter.svg" />
            </a>
          </div>
        </div>

        <div class="container container-projects hide-right">
          <div class="back">
            <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 11">
              <path id="arrow-1" fill-rule="nonzero" d="M.7123704 1.9738796c-.2406605-.2406606-.2406605-.6308486 0-.8715092.2406606-.2406605.6308486-.2406605.8715092 0l4.31375 4.31375c.2406605.2406606.2406605.6308486 0 .8715092l-4.31375 4.31375c-.2406606.2406605-.6308486.2406605-.8715092 0-.2406605-.2406606-.2406605-.6308486 0-.8715092L4.5903659 5.851875.7123704 1.9738796z"></path>
            </svg>
          </div>
          <ul>
            <li class="link-alwaysdata">
              <a target="_blank" href="">Project 1</a>
            </li>
            <li class="link-axa">
              <a class="delay">Project 2</a><span class="soon">SOON</span>
            </li>
            <li class="">
              <a target="_blank" href="">Project 3</a>
            </li>
          </ul>
        </div>

      </div> <!-- End Wrapper -->

    </div> <!-- End Nav Mobile -->
  </fragment>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Header',
  data() {
    return {
      navToggled: false,
      navBtnDisable: false,
    }
  },
  created() {
  },
  mounted() {
    $(window).on('load', function() {

      let $window = $(window);

      $window.scroll(function() {
        if ( $window.scrollTop() >= 1 ) {
          $('#nav-btn').addClass('scroll')
        } else {
          $('#nav-btn').removeClass('scroll')
        }
      });

      //Assign amount of projects in nav
      $('#nav .content .container-prLink ul .link-projects span').html(
        $('#nav .content .container-contentLink .wrapper-el .el-projects ul li').length
      );
      $('#nav-mobile .wrapper .container-linkPr ul li span').html(
        $('#nav .content .container-contentLink .wrapper-el .el-projects ul li').length
      );

    });

    $('#nav .content .container-prLink ul li a').mouseenter(function(){
      if (window.matchMedia("(min-width: 1000px)").matches) {
        $('#nav .content .container-contentLink .wrapper-el .el').removeClass('displayBlock show');
        $('#nav .content .container-contentLink .wrapper-el .' + $(this).data('content')).addClass('displayBlock').outerWidth();
        $('#nav .content .container-contentLink .wrapper-el .' + $(this).data('content')).addClass('show').outerWidth();
      }
    })

    $('#nav-btn-mobile').click(function(){
      if ($('#nav-btn-mobile').hasClass('active')) {
        $('#nav-btn-mobile').removeClass('active');
        $('#nav-mobile').removeClass('show');
      } else {
        $('#nav-btn-mobile').addClass('active');
        $('#nav-mobile').addClass('show');
      }
    })

    $('#nav-mobile .wrapper .container-linkPr ul li .link-delay').click(function(){
      $('#nav-mobile .wrapper .container-linkPr').removeClass('show').addClass('hide-left');
      $('#nav-mobile .wrapper .container-projects').removeClass('hide-right').addClass('show');
    })

    $('#nav-mobile .wrapper .container-projects .back').click(function(){
      $('#nav-mobile .wrapper .container-linkPr').removeClass('hide-left').addClass('show');
      $('#nav-mobile .wrapper .container-projects').removeClass('show').addClass('hide-right');
    })
  },
  methods: {
    toggleNav() {
      this.navToggled = !this.navToggled;

      if (this.navToggled) {
        $('#nav').outerWidth();
      }
    },
    closeNavDesktop() {
      this.navToggled = false;
    }
  }
}
</script>

<style scoped>
</style>
