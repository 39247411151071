<template>
  <div id="footer">
    <a class="container-logo" href="">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 37">
        <use xlink:href=""></use>
      </svg>
    </a>

    <div class="content">
      <div class="container-xeu">
        <div class="crp">
          <a href="/">&copy; {{dateYear}} Servio.io</a>
        </div>
        <div class="sep"></div>
      </div>

      <div class="container-wrp">
        <div class="container-rs">
          <a href="" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 12">
              <use xlink:href=""></use>
            </svg>
          </a>
          <a href="" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
              <use xlink:href=""></use>
            </svg>
          </a>
          <a href="" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 14">
              <use xlink:href=""></use>
            </svg>
          </a>
          <a href="" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 13">
              <use xlink:href=""></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footer-component',
  data() {
    return {
      dateYear: null
    }
  },
  created() {
  },
  mounted() {
    let d = new Date();
    let year = d.getFullYear()

    this.dateYear = year
  }
}
</script>

<style scoped>
</style>
