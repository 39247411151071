<template>
  <div id="projects">
    <Header />
    <div id="container-general">
      <section class="projects-banner">
        <div class="projects-bg">
          <h2>My Work</h2>
          <p>Browse through the numerous projects I have worked on recently, take your time</p>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Header from '@/components/site-header/Header'
  import Footer from '@/components/Footer'

  export default {
    name: 'Projects',
    components: {
      Header,
      Footer
    },
    data() {
      return {}
    },
    mounted() {
      setTimeout(function () {
        document.querySelector('#container-general').classList.add('ready');
      }, 800);
    }
  }
</script>

<style scoped lang="scss">
  .projects-banner {
    position: relative;
    padding-top: 260px;
    padding-bottom: 155px;
    background-color: #003981;
    margin-bottom: 200px
  }

  .projects-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }

  .projects-bg h2 {
    color: #FFF;
    font-family: National-Bold;
    font-size: 2.5em;
    margin-bottom: 0.5em;
  }
  .projects-bg p {
    color: #FFF;
    font-family: National-Bold;
    font-size: 0.8em;
    font-weight: 400;
    letter-spacing: 0.3px;
  }
</style>