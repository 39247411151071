<template>
  <div id="container-general" class="ready">
    <HomeBanner />
    <About />
    <FeaturedProjects />
    <WorkTogether />
    <Skills />
  </div>
</template>

<script>
import HomeBanner from './site-header/HomeBanner';
import WorkTogether from './WorkTogether';
import About from './About';
import FeaturedProjects from '@/components/sections/FeaturedProjects.vue';
import Skills from './Skills'

global.jQuery = require('jquery');
let $ = global.jQuery

export default {
  name: 'page-content',
  components: {
    About,
    HomeBanner,
    FeaturedProjects,
    Skills,
    WorkTogether
  },
  data() {
    return {}
  },
  mounted() {
    this.initScrollAnim()
    this.initResponsive()
  },
  methods: {
    initResponsive() {
      if (window.matchMedia("(max-width: 970px)").matches) {
        $('.section-pr-el .container-el .el .title').each(function(){
          $(this).insertAfter($(this).closest('.el').find('.container-img'));
        })
      }
    },
    initScrollAnim() {

      window.addEventListener('load', () => {

        let $window = $(window);
        var windowHeight = $window.height() / 1.5;

        var distanceSectionContact = $('#section-about').offset().top - windowHeight;

        var distanceEl1 = $('#section-pr-1 .container-el .el:nth-child(1)').offset().top - windowHeight;
        var distanceEl2 = $('#section-pr-1 .container-el .el:nth-child(2)').offset().top - windowHeight;
        var distanceEl3 = $('#section-pr-2 .container-el .el:nth-child(1)').offset().top - windowHeight;
        var distanceEl4 = $('#section-pr-2 .container-el .el:nth-child(2)').offset().top - windowHeight;

        var distanceSectionWork = $('#section-work').offset().top - windowHeight;

        function scrollAnim() {
          if ($window.scrollTop() >= distanceSectionContact) {
            if (!$('#section-about').hasClass('reach')) {
              $('#section-about').addClass('reach');
            }
          }

          if ($window.scrollTop() >= distanceEl1) {
            if (!$('.featured-projects').hasClass('reach')) {
              $('.featured-projects').addClass('reach');
            }
            if (!$('#section-pr-1 .container-el .el:nth-child(1)').hasClass('reach')) {
              $('#section-pr-1 .container-el .el:nth-child(1)').addClass('reach');
            }
          }

          if ($window.scrollTop() >= distanceEl2) {
            if (!$('#section-pr-1 .container-el .el:nth-child(2)').hasClass('reach')) {
              $('#section-pr-1 .container-el .el:nth-child(2)').addClass('reach');
            }
          }

          if ($window.scrollTop() >= distanceEl3) {
            if (!$('#section-pr-2 .container-el .el:nth-child(1)').hasClass('reach')) {
              $('#section-pr-2 .container-el .el:nth-child(1)').addClass('reach');
            }
          }

          if ($window.scrollTop() >= distanceEl4) {
            if (!$('#section-pr-2 .container-el .el:nth-child(2)').hasClass('reach')) {
              $('#section-pr-2 .container-el .el:nth-child(2)').addClass('reach');
            }
          }

          if ($window.scrollTop() >= distanceSectionWork) {
            if (!$('#section-work').hasClass('anim')) {
              $('#section-work').addClass('anim');
            }
          }

          if ($window.scrollTop() + $window.height() == $(document).height()) {
            if (!$('#section-sponsors').hasClass('reach')) {
              $('#section-sponsors').addClass('reach');
            }
          }
        }

        $window.scroll(function() {
          scrollAnim();
        });

        scrollAnim();

      });

    }
  }
}
</script>

<style scoped></style>