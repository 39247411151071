<template>
<section id="section-cover" class="anim">
  <div class="bg-filter" style="background-image: url()"></div>
  <img class="obj-1" src="" alt="" />
  <div class="container-text">
    <h1>
      <p>
        <span>S</span>
        <span>E</span>
        <span>R</span>
        <span>V</span>
        <span>I</span>
        <span>O</span>
        <span style="display:inline-flex;width:0.3em"> </span>
        <span>M</span>
        <span>O</span>
        <span>R</span>
        <span>A</span>
      </p>
      <p>SENIOR DEVELOPER</p>
    </h1>
    <a class="btn delay" href="/contact">
      <p class="anim-btnHover-2">
        <span>H</span>
        <span>I</span>
        <span>R</span>
        <span>E</span>
        <span>&nbsp;</span>
        <span>M</span>
        <span>E</span>
      </p>
    </a>
  </div>
</section>
</template>

<script>

export default {
  name: 'home-banner',
  components: {},
  data() {
    return {}
  }
}
</script>

<style scoped>
#section-cover {
  position: relative;
  padding-top: 260px;
  padding-bottom: 155px;
  background-color: #003981;
}

#section-cover .bg-filter {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

#section-cover .obj-1 {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30%;
  max-width: 500px;
}

#section-cover .container-text {
  position: relative;
  text-align: center;
}

#section-cover .container-text h1 {
  color: #FFFFFF;
  font-family: National-Bold;
  font-size: 54px;
  line-height: 62px;
}

#section-cover .container-text h1>span {
  display: block;
}

#section-cover .container-text h1>span:nth-child(1) span {
  white-space: pre;
  display: inline-block;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  -webkit-transition: all 500ms cubic-bezier(0.045, 0.715, 0.250, 1.000);
  -o-transition: all 500ms cubic-bezier(0.045, 0.715, 0.250, 1.000);
  transition: all 500ms cubic-bezier(0.045, 0.715, 0.250, 1.000);
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(1) {
  -webkit-transition-delay: 0.02s;
  -o-transition-delay: 0.02s;
  transition-delay: 0.02s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(2) {
  -webkit-transition-delay: 0.04s;
  -o-transition-delay: 0.04s;
  transition-delay: 0.04s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(3) {
  -webkit-transition-delay: 0.06s;
  -o-transition-delay: 0.06s;
  transition-delay: 0.06s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(4) {
  -webkit-transition-delay: 0.08s;
  -o-transition-delay: 0.08s;
  transition-delay: 0.08s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(5) {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(6) {
  -webkit-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(7) {
  -webkit-transition-delay: 0.14s;
  -o-transition-delay: 0.14s;
  transition-delay: 0.14s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(8) {
  -webkit-transition-delay: 0.16s;
  -o-transition-delay: 0.16s;
  transition-delay: 0.16s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(9) {
  -webkit-transition-delay: 0.18s;
  -o-transition-delay: 0.18s;
  transition-delay: 0.18s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(10) {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(11) {
  -webkit-transition-delay: 0.22s;
  -o-transition-delay: 0.22s;
  transition-delay: 0.22s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(12) {
  -webkit-transition-delay: 0.24s;
  -o-transition-delay: 0.24s;
  transition-delay: 0.24s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(13) {
  -webkit-transition-delay: 0.26s;
  -o-transition-delay: 0.26s;
  transition-delay: 0.26s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(14) {
  -webkit-transition-delay: 0.28s;
  -o-transition-delay: 0.28s;
  transition-delay: 0.28s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(15) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(16) {
  -webkit-transition-delay: 0.32s;
  -o-transition-delay: 0.32s;
  transition-delay: 0.32s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(17) {
  -webkit-transition-delay: 0.34s;
  -o-transition-delay: 0.34s;
  transition-delay: 0.34s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(18) {
  -webkit-transition-delay: 0.36s;
  -o-transition-delay: 0.36s;
  transition-delay: 0.36s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(19) {
  -webkit-transition-delay: 0.38s;
  -o-transition-delay: 0.38s;
  transition-delay: 0.38s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(20) {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(21) {
  -webkit-transition-delay: 0.42s;
  -o-transition-delay: 0.42s;
  transition-delay: 0.42s
}

#section-cover .container-text h1>span:nth-child(1) span:nth-child(22) {
  -webkit-transition-delay: 0.44s;
  -o-transition-delay: 0.44s;
  transition-delay: 0.44s
}

#section-cover.anim .container-text h1>span:nth-child(1) span {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}

#section-cover .container-text h1>span:nth-child(2) {
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  -webkit-transition: all 500ms cubic-bezier(0.045, 0.715, 0.250, 1.000);
  -o-transition: all 500ms cubic-bezier(0.045, 0.715, 0.250, 1.000);
  transition: all 500ms cubic-bezier(0.045, 0.715, 0.250, 1.000);
  -webkit-transition-delay: 0.46s;
  -o-transition-delay: 0.46s;
  transition-delay: 0.46s
}

#section-cover.anim h1>span:nth-child(2) {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 0.4;
}


#section-cover .container-text .btn {
  display: inline-block;
  margin-top: 30px;
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  padding: 16px 36px 16px 36px;
  text-decoration: none;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  -webkit-transition: all 500ms cubic-bezier(0.045, 0.715, 0.250, 1.000);
  -o-transition: all 500ms cubic-bezier(0.045, 0.715, 0.250, 1.000);
  transition: all 500ms cubic-bezier(0.045, 0.715, 0.250, 1.000);
}

#section-cover.anim .container-text .btn {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}

#section-cover .container-text .btn:hover {
  background-color: #FFF;
}

#section-cover .container-text .btn p {
  color: #FFFFFF;
  font-family: National-Bold;
  font-size: 14px;
}

#section-cover .container-text .btn:hover p {
  color: #003981;
}


@media screen and (max-width: 1250px) {
  #section-cover.anim h1 > span:nth-child(1) {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
  }
  #section-cover.anim h1 > span:nth-child(2) {
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  #section-cover.anim .container-text .btn {
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
}

@media screen and (max-width: 750px) {

  #section-cover {
    padding: 150px 0 100px
  }

  #section-cover .container-text {
    padding-right: 25px;
    padding-left: 25px;
  }

  #section-cover .container-text h1 {
    font-size: 29px;
    line-height: 35px;
  }

  #section-cover .container-text .btn {
    margin-top: 75px;
  }

  #section-cover .obj-1 {
    width: 100%;
  }
}

</style>