<template>
  <div class="el hide"
    :class="[extraClass, {'el-mobile': mobile, 'el-desktop': desktop} ]">
    <div class="illu">
      <div v-if="bgImage || bgColor" class="bg"
        :style="{ 'background-image': `url(${bgImage})`,
        'background-color': `${bgColor}` }">
      </div>
    </div>
    <div class="title">
      <h2>{{ title }}</h2>
    </div>
    <div class="wrapper">
      <div class="container-img" v-if="desktop">
        <img class="desktop" v-if="desktopImage"
          v-lazy="getImgUrl(desktopImage)"
          alt="desktop img"
        >
      </div>
      <div class="container-img" v-else-if="mobile">
        <div class="device">
          <img class="iphone" src="@/assets/img/iPhoneX.svg" alt="iphone template" />
          <div class="container-screen">
            <img
              v-if="mobileOneImage"
              v-lazy="getImgUrl(mobileOneImage)"
              alt="mobile 1 img"
            />
          </div>
        </div>
        <div class="device">
          <img class="iphone" src="@/assets/img/iPhoneX.svg" alt="iphone template" />
          <div class="container-screen">
            <img
              v-if="mobileTwoImage"
              v-lazy="getImgUrl(mobileTwoImage)"
              alt="mobile 2 img"
            />
          </div>
        </div>
      </div>
      <div class="container-text">
        <h3>{{ headline }}</h3>
        <p class="wrapLine">
          <slot></slot>
        </p>
        <a :href="projectUrl" class="delay" target="_blank">
          <p class="anim-btnHover-1">
            <span>D</span>
            <span>I</span>
            <span>S</span>
            <span>C</span>
            <span>O</span>
            <span>V</span>
            <span>E</span>
            <span>R</span>
          </p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Project',
    props: {
      desktop: {
        type: Boolean,
        default: false,
      },
      mobile: {
        type: Boolean,
        default: false,
      },
      elementNumber: {
        type: String,
        required: false,
      },
      bgColor: {
        type: String,
        default: '#EDEDEE'
      },
      projectLink: String,
      bgImage: String,
      title: String,
      headline: String,
      desktopImage: String,
      mobileOneImage: String,
      mobileTwoImage: String,
      extraClass: String,
    },
    data() {
      return {};
    },
    computed: {
      projectUrl() {
        if (this.projectLink !== '' || undefined) {
          return this.projectLink
        } else {
          return ''
        }
      }
    },
    methods: {
      getImgUrl(file) {
        return require(`../../assets/img/${file}`);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .container-img {
    transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
    -webkit-transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); 
  }
  .title {
    transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
    -webkit-transform:translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
  }

  .container-screen img { object-fit: fill }
</style>