<template>
<div>
  <section id="section-work" v-if="desktop">

    <div class="container-title">
      <h2>LET’S WORK <br>TOGETHER</h2>
      <a class="btn-contact delay" href="/contact">
        <p class="anim-btnHover-2">
          <span>C</span>
          <span>O</span>
          <span>N</span>
          <span>T</span>
          <span>A</span>
          <span>C</span>
          <span>T</span>
          <span> </span>
          <span>M</span>
          <span>E</span>
        </p>
      </a>
    </div>
    <div class="container-text">
      <p class="wrapLine anim">
        <span> Let’s build your project </span>
        <span>together. All you need to do is </span>
        <span>fill out the form, and I </span>
        <span>will get back to you within <i>24</i> </span>
        <span><i>hours.</i> . </span>
      </p>
    </div>

    <div class="container-obj">
      <img data-parallax="{&quot;y&quot;: -150, &quot;x&quot;: -150, &quot;smoothness&quot;: 40}" class="obj-1" src="" alt=""
        style="transform:translate3d(-150px, -150px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); -webkit-transform:translate3d(-150px, -150px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); ">
    </div>

  </section>

  <!-- Mobile -->
  <section id="section-work" v-if="mobile">

    <div class="container-title">
      <h2>LET’S WORK <br>TOGETHER</h2>
      <div class="container-text">
        <p class="wrapLine anim">
          <span> Let’s build your project </span>
          <span>together. All you need to do is </span>
          <span>fill out the form, and I </span>
          <span>will get back to you within <br /> </span>
          <span><i>24</i> <i>hours.</i> .</span>
        </p>
      </div>
      <a class="btn-contact delay" href="/contact">
        <p class="anim-btnHover-2">
          <span>C</span>
          <span>O</span>
          <span>N</span>
          <span>T</span>
          <span>A</span>
          <span>C</span>
          <span>T</span>
          <span> </span>
          <span>M</span>
          <span>E</span>
        </p>
      </a>
    </div>

    <div class="container-obj">
      <img data-parallax="{&quot;y&quot;: -150, &quot;x&quot;: -150, &quot;smoothness&quot;: 40}" class="obj-1" :src="image" alt=""
        style="transform:translate3d(-150px, -150px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); -webkit-transform:translate3d(-150px, -150px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); ">
    </div>

  </section>
</div>
</template>

<script>
export default {
  name: "work",
  components: {
  },
  data() {
    return {
      image: require('/src/assets/img/msg-obj.png'),
      desktop: true,
      mobile: false
    }
  },
  created() {
    this.isMobile()
    window.addEventListener("resize", this.isMobile)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.isMobile)
  },
  methods: {
    isMobile() {
      let self = this;

      if( screen.width <= 768 ) {
        self.desktop = false
        self.mobile = true
      }
      else {
        self.desktop = true
        self.mobile = false
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>