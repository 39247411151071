<template>
  <section id="section-about">

    <!-- Add photo of yourself here! -->

    <div class="container-text">
      <h2>ABOUT<br> SERVIO</h2>
      <p class="wrapLine">
        <span>Hey, My name is Servio Mora! Not sure if you noticed, but im a Web Developer that builds pretty cool things, the kind of things that make people say... WOAH that looks awesome, who built this? My dedication and continuous
        pursuit of knowledge ensures that I will always be up to date on the latest trends and technologies, no matter how far we get in the modern digital age. Keep scrolling and have a look, dont be scared to reach out *wink face*.</span>
      </p>
    </div>

    <div class="container-action">
      <a href="/contact" class="delay">
        <span class="btn">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 18">
            <path fill-rule="evenodd" d="M19.727928 15.999827l-5.352826-5.585212-1.238568 1.292492c-.372865.389098-.976916.390726-1.351702.003643L9.882601 9.746105c-.37616-.388502-.379294-1.021658-.007001-1.414195.372293-.392536.979034-.395807 1.355193-.007305l1.228135 1.287773L19.731718 2H.958279C.429036 2 0 1.552285 0 1s.429036-1 .958279-1h20.12646C22.143621 0 23 .893507 23 2.000872v13.998256C23 17.103939 22.144026 18 21.084739 18H6.707955c-.529243 0-.958279-.447715-.958279-1s.429036-1 .958279-1c0 0 9.43904.000511 13.019973-.000173zm1.355688-1.413716c.000328-2.683784.001017-8.489679.001172-11.173302l-5.354474 5.587592 5.353302 5.58571zM2.917856 5h4.707955c.552285 0 1 .447715 1 1s-.447715 1-1 1H2.917856c-.552285 0-1-.447715-1-1s.447715-1 1-1zm1.916559 6h2.791396c.552285 0 1 .447715 1 1s-.447715 1-1 1H4.834415c-.552285 0-1-.447715-1-1s.447715-1 1-1z"></path>
          </svg>
        </span>
        <span class="text">
          <p class="anim-btnHover-1">
            <span>C</span>
            <span>O</span>
            <span>N</span>
            <span>T</span>
            <span>A</span>
            <span>C</span>
            <span>T</span>
            <span> </span>
            <span>M</span>
            <span>E</span>
          </p>
        </span>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {}
  }
}
</script>

<style></style>