<template>
  <div id="home-wrapper">
    <Header />
    <PageContent />
    <Footer />
    <ScrollButton />
  </div>
</template>

<script>
import Header from '@/components/site-header/Header'
import PageContent from '@/components/Content'
import Footer from '@/components/Footer'
import ScrollButton from '@/components/ScrollButton'

export default {
  name: 'home',
  components: {
    PageContent,
    Header,
    Footer,
    ScrollButton
  },
}
</script>

<style scoped></style>
