<template>
  <div id="blog">
    <Header />
    <div id="container-general">
      <section class="blog-banner">
        <div class="blog-bg">
          <h2>BLOG</h2>
        </div>
      </section>
      <section class="blog-posts">
        <h2>Blog Posts Coming Soon!</h2>
      </section>
    </div>
  </div>
</template>

<script>
import Header from '@/components/site-header/Header';

export default {
  name: 'blog',
  components: {
    Header
  },
  data() {
    return {}
  },
  created() {
  },
  mounted() {
    setTimeout(function() {
      document.querySelector('#container-general').classList.add('ready');
    }, 800);
  }
}
</script>

<style scoped>

  #blog .container-rs.show {
    top: 215px !important
  }

  .blog-banner {
    position: relative;
    padding-top: 260px;
    padding-bottom: 155px;
    background-color: #003981;
    margin-bottom: 200px
  }

  .blog-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }

  .blog-bg h2 {
    color: #FFF;
    font-family: National-Bold;
    font-size: 2em
  }

  .blog-posts h2 {
    font-family: National-Bold;
    font-size: 2em
  }

  .blog-posts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    max-width: 800px;
    margin: 0 auto;
  }


</style>
