<template>
  <div class="featured-projects">
    <div class="featured-projects__text-wrapper">
      <div class="featured-projects__text">
        <h2>Featured Projects</h2>
      </div>
    </div>
    <section id="section-pr-1" class="section-pr-el">
      <div class="container-el">
        <Project
          desktop
          extraClass="zara"
          title="Zara Pre-Owned"
          headline="GIVING ZARA PRE-OWNED GARMENTS A NEW LIFE"
          bgColor="#000"
          desktopImage="zara-desktop.png"
          projectLink="https://resell.zara.com/">
            <fragment>
              <span>Custom resell platform built with</span>
              <span>Shopify and Custom App hosted on AWS</span>
              <span>to allow resell of authentic Zara Garments</span>
            </fragment>
        </Project>
        <Project
          desktop
          extraClass=""
          title="SBD Employee Store"
          headline="Internal Shopify Store"
          bgColor="#F7CB0A"
          desktopImage="sbd-online.png">
            <fragment>
              <span>Shopify Plus Storefront for Internal SBD Employees</span>
              <span>Explore the many brands under the Stanley Black &amp; Decker</span>
              <span>Get Started on your new home improvement project</span>
            </fragment>
        </Project>
      </div>
    </section>

    <section id="section-pr-2" class="section-pr-el">
      <div class="container-el">
        <Project
          mobile
          extraClass=""
          title="Love in Faith"
          headline="Christian Apparel For Those Who ..."
          bgColor="#EDD8BC"
          mobileOneImage="Lif-mobile-1.png"
          mobileTwoImage="Lif-mobile-2.png"
          projectLink="https://loveinfaith.life">
            <fragment>
              <span>Custom Shopify Plus Store</span>
              <span>Leveraging Vue JS for all aspects including</span>
              <span>Quick Add, Dynamic Cart and Gift With Purchase</span>
            </fragment>
        </Project>
        <Project
          desktop
          extraClass=""
          title="MacTools"
          headline="Professional Automotive Tools"
          bgColor="#E31837"
          desktopImage="mactools.png"
          projectLink="https://www.mactools.com/">
            <fragment>
              <span>Need the Big Boy Tools for this one?</span>
              <span>Mac Tools has you covered, bringing top quality tools for the professionals</span>
            </fragment>
        </Project>
      </div>
      <a class="link-work" href="" target="">
        <p class="anim-btnHover-1">
          <span>ALL PROJECTS</span>
        </p>
      </a>
    </section>
  </div>
</template>

<script>
  import Project from '@/components/sections/Project.vue';

  export default {
    name: 'FeaturedProjects',
    components: { Project },
    computed: {
    },
    data() {
      return {}
    },
    mounted() {},
    methods: {}
  }
</script>

<style lang="scss" scoped></style>
