<template>
  <div id="site-scroll-top">
    <span class="ticon" aria-hidden="true">
      <img src="../assets/img/arrow-up.png" />
    </span>
    <span class="screen-reader-text">Back To Top</span>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'scroll-top',
  data() {
    return {}
  },
  created() {
  },
  mounted() {

    let $window = $(window);

    $window.scroll(function() {
      if ( $window.scrollTop() >= 300 ) {
        $('#site-scroll-top').addClass('show')
      } else {
        $('#site-scroll-top').removeClass('show')
      }
    });

    $('#site-scroll-top').on('click', function () {
      $('html, body').animate({ scrollTop: 0 },'300');
    })

  },
  methods: {
  }
}
</script>

<style lang="css" scoped>

  #site-scroll-top {
    display: block;
    position: fixed;
    right: 30px;
    bottom: 30px;
    color: #999;
    height: 35px;
    line-height: 35px;
    width: 35px;
    font-size: 16px;
    background: #F6F6F6;
    text-align: center;
    z-index: 998;
    visibility: hidden;
    opacity: 0;
    border-radius: 50%;
    transition: all .25s ease-in-out;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }

  #site-scroll-top img {
    display: block;
    height: 1.5em;
    width: 1.5em;
    margin: 0
  }

  #site-scroll-top:hover {
    border-color: transparent;
    text-decoration: none;
    color: #fff
  }

  #site-scroll-top.show {
    visibility: visible;
    opacity: 1
  }

  .screen-reader-text,.screen-reader-text span,.ui-helper-hidden-accessible {
    position: absolute;
    margin: -1px;
    padding: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;
    word-wrap: normal!important
  }

  .screen-reader-text {
    clip: rect(1px,1px,1px,1px);
    position: absolute!important;
    height: 1px;
    width: 1px;
    overflow: hidden
}

  .screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0,0,0,.6);
    clip: auto!important;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: .875rem;
    font-weight: 700;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000
  }

</style>