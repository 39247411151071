<template>
  <section class="skills">
    <div class="skills-wrapper">

      <div class="skills-wrapper__slide">
        <div class="skills-block">
          <img src="/icons/html5-plain.svg" />
        </div>
        <div class="skills-block">
          <img src="/icons/css3-plain.svg" />
        </div>
        <div class="skills-block">
          <img src="/icons/javascript-plain.svg" />
        </div>
        <div class="skills-block">
          <img src="/icons/vuejs-plain.svg" />
        </div>
        <div class="skills-block">
          <img src="/icons/react-original.svg" />
        </div>
      </div>

      <div class="skills-wrapper__slide">
        <div class="skills-block">
          <img src="/icons/shopify.svg" />
        </div>
        <div class="skills-block">
          <img src="/icons/nodejs-plain.svg" />
        </div>
        <div class="skills-block">
          <img src="/icons/python-plain.svg" />
        </div>
        <div class="skills-block">
          <img src="/icons/mongodb-plain.svg" />
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'skills',
  data() {
    return {}
  },
  created() {
  },
  mounted() {
    this.animateSkills()
  },
  methods: {
    animateSkills() {
      let vm = this;
      $('.skills-wrapper__slide').hide();
      $('.skills .sep').addClass('anim');
      var delayAnimSponsors = 4500;
      var delayScaleSponsors = 1500;
      $('.skills-wrapper__slide:nth-child(1)').show();
      setTimeout(function() {
        $('.skills-wrapper__slide:nth-child(1)').addClass('anim');
      }, 100);
      setTimeout(function() {
        $('.skills-wrapper__slide:nth-child(1)').removeClass('anim');
        setTimeout(function() {
          $('.skills-wrapper__slide:nth-child(1)').hide().insertAfter('.skills-wrapper__slide:last-child()');
          vm.animateSkills();
        }, delayScaleSponsors);
      }, delayAnimSponsors);
    }
  }
}
</script>

<style lang="css" scoped>
</style>