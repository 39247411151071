import Vue from 'vue';
import VueRouter from 'vue-router';
//Views
import Home from '../views/Home.vue'
import Blog from '../views/Blog.vue'
import Projects from '../views/Projects.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;